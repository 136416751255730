import React, {useState, useContext, useEffect} from 'react';
import { Spinner, Button, Form, Row, Col, Table, Pagination, Container } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const HistoryProjects=()=>{
	const { setPage, postCall, load, setLoad, proHistory, setProHisItem, } = useContext(AppContext);
	
	// Default search...
	const [ content, setContent ] = useState();
	useEffect(()=>{
		if (proHistory!==undefined&&content===undefined) {
			setContent(Object.keys(proHistory));
		}
	},[proHistory]);
	const search=(key)=>{
		if (key!==""&&key!==undefined) {
			if(proHistory!==undefined){
			    if(Object.keys(proHistory).length>0){
			    	setContent(prev=>([]));
			    	Object.keys(proHistory).map(item=>{
			    		if (proHistory[item].name.toLowerCase().includes(key.toLowerCase())) {
			    			setContent(prev=>([...prev,item]));
			    		}
			    	});
			    }
			}
		}else{
			setContent(Object.keys(proHistory));
		}
	}
	// Pagination
	const [ numPage, setNumPage ] = useState(0);
	const [ activePage, setActivePage ] = useState(0);
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=5;	
	// number of pages in pagination
	const pageCount = 10;
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body">					
					<Row className="dash-body-head bdr-1b px-1 bg1 mb-3">
						<Col className="d-grid al-center py-2 tc-blue" >
							{
								load?(
									<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
								):'Completed Projects'
							}
						</Col>
						<Col className="d-flex just-end al-center gap-1">
						</Col>
					</Row>
					<Container fluid>
						<Row className="dash-body-content">
							{
								proHistory!==undefined&&Object.keys(proHistory).length>0&&content!==undefined?(
									content.length>0?(
										content.map((item,i)=>(
											activePage!==undefined&&i>=activePage*itemCount&&i<activePage*itemCount+itemCount?(
												<Col onClick={()=>{
													setPage('project-history-item');
													setLoad(true);
													postCall('projects/get/history/by/id/',{p_id:item.substring(1)}).then(res=>{
														setProHisItem(res.data);
														setLoad(false);
													}).catch(err=>{
														setLoad(false);
													});
												}} xs={12} sm={12} md={6} lg={6} xl={4} key={`pro_${i}`} className="mb-3 cpoint">
													<div className="pro-item">
														<span className="pro-head  d-grid p-2" >{proHistory[item].name}</span>
														<div className="pro-body d-grid p-2">
															<div className="pro-det-item">
																<span>Location</span>
																<span>:</span>
																<span>{proHistory[item].location}</span>
															</div>
															<div className="pro-det-item">
																<span>Contact person</span>
																<span>:</span>
																<span>{proHistory[item].contact_name}</span>
															</div>
															<div className="pro-det-item">
																<span>Contact number</span>
																<span>:</span>
																<span>{proHistory[item].phone}</span>
															</div>
															<div className="pro-det-item">
																<span>Start Date</span>
																<span>:</span>
																<span>{proHistory[item].start_date}</span>
															</div>
															<div className="pro-det-item">
																<span>End Date</span>
																<span>:</span>
																<span>{proHistory[item].end_date}</span>
															</div>
														</div>
													</div>
												</Col>
											):''
										))
									):'Result NOT Found'
								):'Projects NOT Found! Please add project.'
							}
						</Row>
						<div className="d-grid just-center py-2">
							{
							    numPage!==undefined&&numPage>1?(
									<Pagination>
								      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
								      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
								      	{
								      		Array(numPage).fill('1').map((item,i)=>(
								      			i>=indexStart&&i<indexStart+pageCount?(
						      						activePage!==undefined&&activePage===i?(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
						      						):(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
						      						)
						      					):''
						      				))
								      	}				      	
								      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
								      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
								    </Pagination>
								):''
							}
					    </div>
					</Container>		
				</Col>
			</Row>
		)
}