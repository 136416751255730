import React, {useState, useContext, useEffect} from 'react';
import { Spinner, Button, Modal, Form, Row, Col, Container, Table } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const HistoryProjectItem=()=>{
	const { setPage, postCall, getLoad, load, proHisItem } = useContext(AppContext);
	
	return(
			<Row className="dash-content" >
				<Col xs={12} sm={12} md={12} className="dash-body">
						<Row className="dash-body-head bdr-1b px-1 bg1 mb-3">
							<Col xs={12} sm={12} md={6} className="flex-container gap-1 al-center py-2 tc-blue" >
								<Button onClick={()=>{setPage('pro-history')}} className="btn-t1 br3" size="sm" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
								{
									load?(
										<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
									):(
										proHisItem!==undefined?proHisItem.project.name:'Something went wrong!'
									)
								}
							</Col>
							<Col xs={12} sm={12} md={6} className="d-flex just-end al-center" >
							</Col>
						</Row>
						<Container fluid>
							<Row className="dash-body-content">
								<Col xs={12} sm={12} md={12} className="bdr-2 br3 mb-3" >
									<Row className="py-1 bg2 bdr-2b al-center" >
										<Col>
											<span className="f18" >Machine & Tools Used</span>
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} md={12} className="p-2" >
										<Table bordered className="tbl-bg-blue mb-3" >
										    <thead>
										        <tr className="tc1" >
										          <th>Machine</th>
										          <th>Start Date</th>
										          <th>End Date</th>
										          <th className="text-end" >Days Used</th>
										        </tr>
										    </thead>
										    <tbody>
												{
													proHisItem!==undefined&&Object.keys(proHisItem.history).length>0?(
														Object.keys(proHisItem.history).map((item,i)=>(
															<tr key={`mac_${i}`} className="tc1"  >
																<td>
																	<span className="d-grid f18" >{proHisItem.history[item].item} / {proHisItem.history[item].code}</span>
																	<span className="f14 tc2" >{proHisItem.history[item].manufacturer}, {proHisItem.history[item].category}, {proHisItem.history[item].sub_category}</span>
																</td>
																<td>{proHisItem.history[item].entry_date}</td>
																<td>{proHisItem.history[item].end_date}</td>
																<td className="text-end" >{proHisItem.history[item].days}</td>
															</tr>
														))
													):(<span className="p-2" >Machine & Tools Not used!</span>)
												}
											</tbody>
							        	</Table>
										</Col>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} className="bdr-2 br3" >
									<Row className="py-1 bg2 bdr-2b al-center" >
										<Col>
											<span className="f18" >Consumbles Used</span>
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} md={12} className="py-2" >
											<Table bordered className="tbl-bg-blue mb-3" >
											    <thead>
											        <tr className="tc1" >
											          <th>Date</th>
											          <th>Consumbles</th>
											          <th>Unit</th>
											          <th>Quantity</th>
											        </tr>
											    </thead>
											    <tbody>
													{
														proHisItem!==undefined&&Object.keys(proHisItem.consum).length>0?(
															Object.keys(proHisItem.consum).map((item,i)=>(
																<tr key={`mac_${i}`} className="tc1"  >
																	<td>{proHisItem.consum[item].use_date}</td>
																	<td>
																		<span className="d-grid f18" >{proHisItem.consum[item].item} / {proHisItem.consum[item].code}</span>
																		<span className="f14 tc2" >{proHisItem.consum[item].category}</span>
																	</td>
																	<td>{proHisItem.consum[item].unit}</td>
																	<td>{proHisItem.consum[item].qty}</td>
																</tr>
															))
														):(<span className="p-2" >Consumables Not used!</span>)
													}
												</tbody>
								        	</Table>
										</Col>
									</Row>
								</Col> 
							</Row>
						</Container>
				</Col>
			</Row>
		)
}