import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { AppContext } from '../App';
import { Home } from './pages/home';
import { Users } from './pages/users/users';
import { MachineTools } from './pages/mt/mt';
import { MachineToolsCategory } from './pages/mt/category';
import { ConsumeCategory } from './pages/consume/category';
import { Consumables } from './pages/consume/consumables';
import { OngoingProjects } from './pages/projects/projects';
import { ActiveProject } from './pages/projects/project';
import { HistoryProjects } from './pages/projects/history';
import { HistoryProjectItem } from './pages/projects/his_item';

export const Dashboard=()=>{
	const { page } = useContext(AppContext);
	return(
			<Col xs={12} sm={12} md={8} lg={9} xl={10} className="container-main">
				
				{
					page !==undefined?(
						page === 'home'?(
							<Home />
						):page === 'user'?(
							<Users />
						):page === 'mt'?(
							<MachineTools />
						):page === 'm-category'?(
							<MachineToolsCategory />
						):page==='c-cat'?(
							<ConsumeCategory />
						):page==='c-item'?(
							<Consumables />
						):page==='project'?(
							<OngoingProjects />
						):page==='project-item'?(
							<ActiveProject />
						):page==='pro-history'?(
							<HistoryProjects />
						):page==='project-history-item'?(
							<HistoryProjectItem />
						):''
					):''
				}
        	</Col>
		)
}