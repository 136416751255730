import React, {useState, useContext, useEffect} from 'react';
import { Spinner, Button, Modal, Form, Row, Col, Pagination, Container } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import { AppContext } from '../../../App';


export const OngoingProjects=()=>{
	const { setPage, postCall, setGetLoad, load, setLoad, projects, setProjects, setProItem, setActivePro, setAvailMt, getCall } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ editId, setEditId ] = useState();
	const [ editable, setEditable ] = useState(false);
	const updateItem=(e)=>{
		e.preventDefault();
		let name = e.target.p_name.value;
		let super_v = e.target.p_super.value;
		let start = e.target.p_date.value;
		let loc = e.target.p_location.value;
		let other_det = e.target.p_other_det.value;
		let email = e.target.p_email.value;
		let phone = e.target.p_phone.value;
		let b_add = e.target.p_b_address.value;
		if (editable&&editId!==undefined) {
			if (name!=="") {
				setUpdateLoad(true);
				postCall('projects/edit/project/',{i_id:editId.substring(1), name:name,contact_name:super_v, start_date:start,location:loc,
					other_details:other_det,billing_address:b_add,email:email,phone:phone}).then(res=>{
					setProjects(res.data);				
					setUpdateLoad(false);
					setUpdateModal(false);				
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}else{
			if (name!=="") {
				setUpdateLoad(true);
				postCall('projects/add/project/',{name:name,contact_name:super_v, start_date:start,location:loc,
					other_details:other_det,billing_address:b_add,email:email,phone:phone}).then(res=>{
					setProjects(res.data);
					setContent(Object.keys(res.data));				
					setUpdateLoad(false);
					setUpdateModal(false);		
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}

	}
	// Default search...
	const [ content, setContent ] = useState();
	useEffect(()=>{
		if (projects!==undefined&&content===undefined) {
			setContent(Object.keys(projects));
		}
	},[projects]);
	const search=(key)=>{
		if (key!==""&&key!==undefined) {
			if(projects!==undefined){
			    if(Object.keys(projects).length>0){
			    	setContent(prev=>([]));
			    	Object.keys(projects).map(item=>{
			    		if (projects[item].name.toLowerCase().includes(key.toLowerCase())) {
			    			setContent(prev=>([...prev,item]));
			    		}
			    	});
			    }
			}
		}else{
			setContent(Object.keys(projects));
		}
	}
	// Pagination
	const [ numPage, setNumPage ] = useState(0);
	const [ activePage, setActivePage ] = useState(0);
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=5;	
	// number of pages in pagination
	const pageCount = 10;
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body">					
					<Row className="dash-body-head bdr-1b px-1 bg1 mb-3">
						<Col className="d-grid al-center py-2 tc-blue" >
							{
								load?(
									<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
								):'Projects'
							}
						</Col>
						<Col className="d-flex just-end al-center gap-1">
							<Button onClick={()=>{
								setUpdateModal(true);
							}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New Project</Button>
						</Col>
					</Row>
					<Container fluid>
						<Row className="dash-body-content">
							{
								projects!==undefined&&Object.keys(projects).length>0&&content!==undefined?(
									content.length>0?(
										content.map((item,i)=>(
											activePage!==undefined&&i>=activePage*itemCount&&i<activePage*itemCount+itemCount?(
												<Col onClick={()=>{
													setPage('project-item');
													setActivePro(item);
													setLoad(true);
													postCall('projects/get/project/by/id/',{p_id:item.substring(1)}).then(res=>{
														setProItem(res.data);
														setLoad(false);
													}).catch(err=>{
														setLoad(false);
													});
													getCall(setGetLoad,'projects/get/m/items/',setAvailMt);
												}} xs={12} sm={12} md={6} lg={6} xl={4} key={`pro_${i}`} className="mb-3 cpoint">
													<div className="pro-item">
														<span className="pro-head  d-grid p-2" >{projects[item].name}</span>
														<div className="pro-body d-grid p-2">
															<div className="pro-det-item">
																<span>Status</span>
																<span>:</span>
																<span>{projects[item].status}</span>
															</div>
															<div className="pro-det-item">
																<span>Location</span>
																<span>:</span>
																<span>{projects[item].location}</span>
															</div>
															<div className="pro-det-item">
																<span>Contact person</span>
																<span>:</span>
																<span>{projects[item].contact_name}</span>
															</div>
															<div className="pro-det-item">
																<span>Contact number</span>
																<span>:</span>
																<span>{projects[item].phone}</span>
															</div>
															<div className="pro-det-item">
																<span>Start Date</span>
																<span>:</span>
																<span>{projects[item].start_date}</span>
															</div>
														</div>
													</div>
												</Col>
											):''
										))
									):'Result NOT Found'
								):'Projects NOT Found! Please add project.'
							}
						</Row>
						<div className="d-grid just-center py-2">
							{
							    numPage!==undefined&&numPage>1?(
									<Pagination>
								      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
								      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
								      	{
								      		Array(numPage).fill('1').map((item,i)=>(
								      			i>=indexStart&&i<indexStart+pageCount?(
						      						activePage!==undefined&&activePage===i?(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
						      						):(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
						      						)
						      					):''
						      				))
								      	}				      	
								      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
								      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
								    </Pagination>
								):''
							}
					    </div>
					</Container>		
				</Col>
				<Modal size="xl" fullscreen="lg-down" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        	<Modal.Header closeButton>Add new</Modal.Header>
		        	<Modal.Body>
			        	<div className="bdr-1 mb-2 bg-greay-1 p-2 br3" >
				        	<Form onSubmit={updateItem} id="purchase_add">
				        		<Row>
				        			<Col xs={12} sm={12} md={12}>
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Project name *</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text" name="p_name" className="shadow-none br3" required/>
						        			</div>
						        		</Form.Group>
						        	</Col>
				        		</Row>
				        		<Row>				        			
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Contact person name</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text"  name="p_super" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Email id</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="email"  name="p_email" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Contact number</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text"  name="p_phone" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			
				        		</Row>
				        		<Row>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Start date</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="date"  name="p_date" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Location</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text"  name="p_location" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={8} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Billing address</Form.Label>
						        			<Form.Control type="text" as="textarea" name="p_b_address" rows="4" className="shadow-none br3"  />
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Other Details</Form.Label>
						        			<Form.Control type="text" as="textarea" name="p_other_det" rows="5" className="shadow-none br3"  />
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
					        			<Form.Group className="mb-3 d-grid al-center">			        		
							        		{
										    	updateLoad?(
										    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
										    	):(
										    	<Button type="submit" id="add_btn" className="btn-t1 br3" ><i className="bi bi-plus-lg"></i> Add Item</Button>
										    	)
										    }
									    </Form.Group>
					        		</Col>
				        		</Row>
				        		
			        		</Form>
		        		</div>
		        	</Modal.Body>
		        	<Modal.Footer></Modal.Footer>
        		</Modal>
			</Row>
		)
}