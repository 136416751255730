import React, {useState, useContext, useEffect} from 'react';
import { Alert, Spinner, Button, Modal, Form, Row, Col, Table, Pagination, Container } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const ConsumeCategory=()=>{
	const { setPage, postCall, getLoad, load, conCat, setConCat } = useContext(AppContext);
	const [ catUpModal, setCatUpModal ] = useState(false);
	const [ catUpLoad, setCatUpLoad ] = useState(false);
	const [ editable, setEditable ] = useState(false);
	const [ editId, setEditId ] = useState();
	const updateCat=(e)=>{
		e.preventDefault();
		let name = e.target.cat.value;
		if (name!=="") {
			setCatUpLoad(true);
			if (editable&&editId!==undefined) {
				postCall('materials/edit/c/category/',{name:name,c_id:editId.substring(1)}).then(res=>{
					setConCat(res.data);
					setCatUpLoad(false);
					setCatUpModal(false);
					setContent(Object.keys(res.data));
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}else{
				postCall('materials/add/c/category/',{name:name}).then(res=>{
					setConCat(res.data);
					setCatUpLoad(false);
					setCatUpModal(false);
					setContent(Object.keys(res.data));
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}
		}
	}
	useEffect(()=>{
		if (!catUpModal) {
			setEditable(false);
			setEditId();
		}
	},[catUpModal]);
	const [ activeItem, setActiveItem ] = useState();
	const [ changeStatusLoad, setChangeStatusLoad ] = useState(false);
	const changeStatus=(cid,status)=>{
		if (cid!==""&&status!=="") {
			setActiveItem(cid);
			setChangeStatusLoad(true);
			postCall('materials/status/c/category/',{c_id:cid.substring(1),status:status}).then(res=>{
				setChangeStatusLoad(false);
				setActiveItem();
				setConCat(res.data);
			}).catch(err=>{
				setChangeStatusLoad(false);
				setActiveItem();
			});
		}
	}
	// Default search...
	const [ content, setContent ] = useState();
	useEffect(()=>{
		if (conCat!==undefined&&content===undefined) {
			setContent(Object.keys(conCat));
		}
	},[conCat]);
	const search=(key)=>{
		if (key!==""&&key!==undefined) {
			if(conCat!==undefined){
			    if(Object.keys(conCat).length>0){
			    	setContent(prev=>([]));
			    	Object.keys(conCat).map(item=>{
			    		if (conCat[item].name.toLowerCase().includes(key.toLowerCase())) {
			    			setContent(prev=>([...prev,item]));
			    		}
			    	});
			    }
			}
		}else{
			setContent(Object.keys(conCat));
		}
	}
	// Pagination
	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState(0);
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=5;	
	// number of pages in pagination
	const pageCount = 10;
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body p-4">
					<Container className="bdr-1 br2" fluid>
						<Row className="dash-body-head bdr-1b px-1 bg1">
							<Col className="d-grid al-center py-2 tc-blue" >
								{
									load?(
										<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
									):'Categories'
								}
							</Col>
							<Col className="d-flex just-end al-center gap-1">
								<Button onClick={()=>{
									setCatUpModal(true);
								}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New Category</Button>
							</Col>
						</Row>
						<Row className="dash-body-content">
							<Col>
							{
								conCat!==undefined&&Object.keys(conCat).length>0&&content!==undefined?(
									content.length>0?(
										content.map((item,i)=>(
											activePage!==undefined&&i>=activePage*itemCount&&i<activePage*itemCount+itemCount?(
												<Row className="bdr-1b py-2" key={`cat_${i}`} >
													<Col xs={12} sm={12} md={6} >
														<span className="f18 tc-blue f200 d-flex al-end gap-1" >
														{
															conCat[item].status?(
																<span className="sucess-tag f16 px-1 br3" ><i className="bi bi-check-circle-fill"></i></span>
															):(
																<span className="error-tag f16 px-1 br3" ><i className="bi bi-x-circle-fill"></i></span>
															)
														}
														{ conCat[item].name }</span>
													</Col>
													<Col xs={12} sm={12} md={6} className="flex-container just-end al-center">
														<Button onClick={()=>{
															setEditable(true);
															setEditId(item);
															setCatUpModal(true);
														}} className="btn-t1 br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
														{
															activeItem!==undefined&&activeItem===item&&changeStatusLoad?(
																<Button className="btn-t1 br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
															):(
																conCat[item].status?(
																	<Button onClick={()=>{changeStatus(item,false)}} className="btn-disable br3 d-flex gap-1" size="sm" ><i className="bi bi-x-circle-fill d-flex al-center"></i> Disable</Button>																
																):(																
																	<Button onClick={()=>{changeStatus(item,true)}} className="btn-enable br3 d-flex gap-1" size="sm" ><i className="bi bi-check-circle-fill d-flex al-center"></i> Activate</Button>
																)
															)
														}
													</Col>						
												</Row>
											):''
										))
									):'Result NOT Found'
								):'Category NOT Found! Please add category.'
							}
							</Col>
						</Row>
						<div className="d-grid just-center py-2">
							{
							    numPage!==undefined&&numPage>1?(
									<Pagination>
								      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
								      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
								      	{
								      		Array(numPage).fill('1').map((item,i)=>(
								      			i>=indexStart&&i<indexStart+pageCount?(
						      						activePage!==undefined&&activePage===i?(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
						      						):(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
						      						)
						      					):''
						      				))
								      	}				      	
								      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
								      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
								    </Pagination>
								):''
							}
					    </div>
					</Container>
					
				</Col>
				<Modal size="md" show={catUpModal} backdrop="static" onHide={()=>{setCatUpModal(false)}}>
		        	<Modal.Header closeButton>Category</Modal.Header>		        	
			        <Modal.Body>
			        	<Form onSubmit={updateCat} >
						    <Form.Group className="mb-3">
			        			<Form.Label>Category Name</Form.Label>
						    	<Form.Control defaultValue={editable&&editId!==undefined?conCat[editId].name:''} type="text" name="cat"  className="shadow-none br-2" required/>
						    </Form.Group>
						    <div  className="d-grid">
						    {
						    	catUpLoad?(
						    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    		<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
						    </div>
			        	</Form>
			        </Modal.Body>
			        <Modal.Footer></Modal.Footer>
			    </Modal>
			</Row>
		)
}