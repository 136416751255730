import React, {useState, useContext, useEffect} from 'react';
import { Alert, Spinner, Button, Modal, Form, Row, Col, Table, Pagination } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const Users=()=>{
	const { setPage, postCall, getLoad, load, users, setUsers, userPerm  } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ edit, setEdit ] = useState(false);
	const [ editId, setEditId ] = useState();
	const [ permissions, setPermissions ] = useState([]);	
	const [ validUsername, setValidUsername ] = useState();
	const [ checkUserLoad, setCheckUserLoad ] = useState(false);
	const [ validEmail, setValidEmail ] = useState();
	const [ checkEmailLoad, setCheckEmailLoad ] = useState(false);
	useEffect(()=>{
		if (!updateModal) {
			setPermissions([]);
			setValidUsername();
			setValidEmail();
			setEditId();
			setEdit(false);		
		}
	},[updateModal]);
	const updateUser=(e)=>{
		e.preventDefault();
		let desi = e.target.desig.value;
		let fname = e.target.fname.value;
		let lname = e.target.lname.value;
		if (fname==="") {
			document.querySelector('#fname').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#fname').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#fname').classList.remove('bdr-red');}				
		if (edit) {
			if (fname!=="") {
				setUpdateLoad(true);
				postCall('user/management/edit/user/',{first_name:fname,last_name:lname,designation:desi,permission:permissions,u_id:editId.substring(1)}).then(res=>{
					setUpdateLoad(false);
					setUsers(res.data);
					setUpdateModal(false);
				}).catch(err=>{
					setUpdateLoad(false);
				});
			}
		}else{
			let uname = e.target.uname.value;
			let email = e.target.email.value;			
			let pass = e.target.pass.value;
			let cpass = e.target.cpass.value;
			if (uname==="") {
				document.querySelector('#uname').classList.add('bdr-red');
				setTimeout(()=>{document.querySelector('#uname').classList.remove('bdr-red');},5000);
			}else{document.querySelector('#uname').classList.remove('bdr-red');}
			if (email==="") {
				document.querySelector('#uemail').classList.add('bdr-red');
				setTimeout(()=>{document.querySelector('#uemail').classList.remove('bdr-red');},5000);
			}else{document.querySelector('#uemail').classList.remove('bdr-red');}
			if (pass==="") {
				document.querySelector('#pass').classList.add('bdr-red');
				setTimeout(()=>{document.querySelector('#pass').classList.remove('bdr-red');},5000);
			}else{document.querySelector('#pass').classList.remove('bdr-red');}
			if (cpass==="") {
				document.querySelector('#cpass').classList.add('bdr-red');
				setTimeout(()=>{document.querySelector('#cpass').classList.remove('bdr-red');},5000);
			}else{document.querySelector('#cpass').classList.remove('bdr-red');}
			if (pass!==""&&cpass!==""&&pass!==cpass) {
				$.dialog({
	              title: "Password Error!",
	              type: 'red',
	              content: 'Password and Confirm password do not matches!',
	            });
			}
			if (fname!==""&&uname!==""&&email!==""&&pass!==""&&cpass!==""&&pass===cpass&&!validEmail&&!validUsername) {
				setUpdateLoad(true);
				postCall('user/management/add/user/',{first_name:fname,last_name:lname,designation:desi,username:uname,email:email,password:pass,permission:permissions}).then(res=>{
					setUpdateLoad(false);
					setUsers(res.data);
					setUpdateModal(false);
				}).catch(err=>{
					setUpdateLoad(false);
				});
			}
		}

	}
	const changePermission=(slug)=>{
		if (slug!==""&&slug!==null) {
			if (permissions.indexOf(slug)===-1) {
				setPermissions(prev=>[...prev,slug]);
			}else{
				setPermissions(prev=>[...prev.filter(e=>e!==slug)]);
			}
		}
	}
	const [ changePassModel, setChangePassModel ] = useState(false);
	const [ changePassLoad, setChangePassLoad ] = useState(false);
	const changePassword=(e)=>{
		e.preventDefault();
		let pass = e.target.pass.value;
		let cpass = e.target.cpass.value;
		if (pass==="") {
			document.querySelector('#c_pass').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#c_pass').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#c_pass').classList.remove('bdr-red');}
		if (cpass==="") {
			document.querySelector('#c_cpass').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#c_cpass').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#c_cpass').classList.remove('bdr-red');}
		if (pass!==""&&cpass!==""&&pass!==cpass) {
			$.dialog({
              title: "Password Error!",
              type: 'red',
              content: 'Password and Confirm password do not matches!',
            });
		}
		if (pass!==""&&cpass!==""&&pass===cpass) {
			setChangePassLoad(true);
			postCall('user/management/change/user/password/',{u_id:editId.substring(1),pass:pass}).then(res=>{
				setChangePassLoad(false);
				setChangePassModel(false);
				$.dialog({
	              title: "Success!",
	              type: 'green',
	              content: 'Password successfully changed!',
	            });
	            
			}).catch(err=>{
				setChangePassLoad(false);
			});
		}

	}
	useEffect(()=>{if (!changePassModel){setEditId();} },[changePassModel]);
	const [ changeStatusLoad, setChangeStatusLoad ] = useState(false);
	const changeStatus=(id,status)=>{
		if (id!==""&&status!=="") {
			setChangeStatusLoad(false);
			setEditId(id);
			postCall('user/management/change/user/status/',{u_id:id.substring(1),status:status}).then(res=>{
				setChangePassLoad(false);
				setUsers(res.data);
				setEditId();
			}).catch(err=>{
				setChangePassLoad(false);
				setEditId();
			});
		}
	}
	return(
			<Row className="dash-content" >
				<Col xs={12} sm={12} md={12} className="dash-body">
						<Row className="dash-body-head bdr-1b px-1 bg1">
							<Col className="d-grid al-center py-2 tc-blue" >
								{
									load?(
										<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
									):'Users'
								}
							</Col>
							<Col className="d-flex just-end al-center gap-1">
								<Button onClick={()=>{
									setUpdateModal(true);									
								}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New</Button>
							</Col>
						</Row>
						<Row className="dash-body-content">
							<Col>
							{
								users!==undefined&&Object.keys(users).length>0?(
									Object.keys(users).map((usr,usr_i)=>(
										<Row className="bdr-1b" key={`user_${usr_i}`} >
											<Col className="d-grid px-3 py-2" >
												<span className="tc2 d-flex gap-1 mb-1" >
													{
														users[usr].user_status?(
															<span className="sucess-tag f16 px-1 br3" ><i className="bi bi-check-circle-fill"></i></span>
														):(
															<span className="error-tag f16 px-1 br3" ><i className="bi bi-x-circle-fill"></i></span>
														)
													}{users[usr].designation} / {users[usr].first_name} {users[usr].last_name}</span>
												<span className="tc3 d-grid f12">{users[usr].user_name} / {users[usr].email}</span>
											</Col>
											<Col className="d-flex just-end al-center gap-1 px-3">
												<Button onClick={()=>{
													setEditId(usr);
													setChangePassModel(true);
												}} className="btn-t1 br3" size="sm" >Change password</Button>
												<Button onClick={()=>{
													setEdit(true);
													setEditId(usr);
													setUpdateModal(true);
													setPermissions(users[usr].permissions);
												}} className="btn-t1 br3" size="sm">Edit</Button>
												{
													editId!==undefined&&editId===usr&&changeStatusLoad?(
														<Button className="btn-t1-r br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
													):(
														users[usr].user_status?(
															<Button onClick={()=>{changeStatus(usr,false)}} className="btn-disable br3 d-flex gap-1" size="sm" ><i className="bi bi-x-circle-fill d-flex al-center"></i> Disable</Button>																
														):(																
															<Button onClick={()=>{changeStatus(usr,true)}} className="btn-enable br3 d-flex gap-1" size="sm" ><i className="bi bi-check-circle-fill d-flex al-center"></i> Activate</Button>
														)
													)
												}
												
											</Col>
										</Row>
									))
								):(<Alert variant="info m-3" >Users NOT found!</Alert>)
							}
							</Col>
						</Row>
				</Col>
				<Modal size="xl" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        	<Modal.Header closeButton>
			        	{
			        		edit&&editId!==undefined?(
			        			<span className="tc-blue f18 f200" >Update</span>
			        		):(
			        			<span className="tc-blue f18 f200" >Add New</span>
			        		)
			        	}
		        	</Modal.Header>
		        	<Modal.Body>
		        		<Form onSubmit={updateUser} >
		        			<Form.Group className="mb-3">
			        			<Form.Label className="tc2" >Designation</Form.Label>
			        			<Form.Control type="text" defaultValue={edit&&editId!==undefined?users[editId].designation:''} name="desig" className="shadow-none br3" />
			        		</Form.Group>
		        			<Row>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Group className="mb-3">
					        			<Form.Label className="tc2" >First Name</Form.Label>
					        			<Form.Control type="text" defaultValue={edit&&editId!==undefined?users[editId].first_name:''} name="fname" className="shadow-none br3" id="fname" />
					        		</Form.Group>
						    	</Col>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Group className="mb-3">
					        			<Form.Label className="tc2" >Last Name</Form.Label>
					        			<Form.Control type="text" defaultValue={edit&&editId!==undefined?users[editId].last_name:''} name="lname" className="shadow-none br3" />
					        		</Form.Group>
						    	</Col>
						    </Row>
						    {
						    	edit!==undefined&&!edit?(
							    	<>
									    <Row>
									    	<Col xs={12} sm={12} md={6} >
									    		<Form.Group className="mb-3">
								        			<Form.Label className="tc2" >Username 
					        						{
						        						checkUserLoad?(
						        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
						        						):(
								        					validUsername!==undefined?(
								        						validUsername?(
								        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
								        						):(
								        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
								        						)
								        					):''
							        					)
						        					}
							        				</Form.Label>
									        			<Form.Control type="text" onBlur={(e)=>{
									        				let uname = e.target.value;
									        				setCheckUserLoad(true);
									        				postCall('user/management/get/username/',{username:uname}).then(res=>{
									        					setValidUsername(res.data);
									        					setCheckUserLoad(false);
									        				}).catch(err=>{
									        					setCheckUserLoad(false);
									        				});
									        			}} name="uname" className="shadow-none br3" id="uname" />
									        		</Form.Group>
									    	</Col>
									    	<Col xs={12} sm={12} md={6} >
									    		<Form.Group className="mb-3">
								        			<Form.Label className="tc2" >Email 
								        			{
						        						checkEmailLoad?(
						        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
						        						):(
								        					validEmail!==undefined?(
								        						validEmail?(
								        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
								        						):(
								        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
								        						)
								        					):''
							        					)
						        					}
								        			</Form.Label>
								        			<Form.Control onBlur={(e)=>{
									        				let email = e.target.value;
									        				setCheckEmailLoad(true);
									        				postCall('user/management/get/emailId/',{email:email}).then(res=>{
									        					setValidEmail(res.data);
									        					setCheckEmailLoad(false);
									        				}).catch(err=>{
									        					setCheckEmailLoad(false);
									        				});
									        			}} type="email" name="email" className="shadow-none br3" id="uemail" />
								        		</Form.Group>
									    	</Col>
									    </Row>
									    <Row>
									    	<Col xs={12} sm={12} md={6} >
									    		<Form.Group className="mb-3">
								        			<Form.Label className="tc2" >Password</Form.Label>
								        			<Form.Control type="password" name="pass" className="shadow-none br3" id="pass" />
								        		</Form.Group>
									    	</Col>
									    	<Col xs={12} sm={12} md={6} >
									    		<Form.Group className="mb-3">
								        			<Form.Label className="tc2" >Confirm password</Form.Label>
								        			<Form.Control type="password" name="cpass" className="shadow-none br3" id="cpass" />
								        		</Form.Group>
									    	</Col>
									    </Row>
									</>
								):''
							}
			        		<Form.Group className="mb-3">
			        			<Form.Label className="tc2 d-grid" >Permissions</Form.Label>
			        			<div className="px-2 py-3 bdr-1 br3 d-flex gap-2 flex-wrap" >
			        				{
			        					userPerm!==undefined&&Object.keys(userPerm).length>0?(
			        						Object.keys(userPerm).map((perm,permi)=>(
			        							permissions!==undefined&&permissions.indexOf(userPerm[perm].slug)===-1?(
			        								<span onClick={()=>{changePermission(userPerm[perm].slug)}} key={`permis_${permi}`} className="perm-item" ><i className="bi bi-x-circle"></i> {userPerm[perm].name}</span>
			        							):(
			        								<span onClick={()=>{changePermission(userPerm[perm].slug)}} key={`permis_${permi}`} className="perm-item-active" ><i className="bi bi-check-circle-fill"></i> {userPerm[perm].name}</span>
			        							)
			        							
			        						))
			        					):(<Alert variant="warning m-3" >Permissions NOT found!</Alert>)
			        				}
			        			</div>
			        		</Form.Group>
			        		<div  className="d-grid">
						    {
						    	updateLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	<Button type="submit" className="btn-t1 br3">SAVE</Button>
						    	)
						    }
						    </div>
		        		</Form>
		        	</Modal.Body>
		        	<Modal.Footer>
		        	</Modal.Footer>
        		</Modal>
        		<Modal size="md" show={changePassModel} backdrop="static" onHide={()=>{setChangePassModel(false)}}>
		        	<Modal.Header closeButton>Change password</Modal.Header>		        	
			        <Modal.Body>
			        	<Form onSubmit={changePassword} >
						    <Form.Group className="mb-3">
			        			<Form.Label>New Password</Form.Label>
						    	<Form.Control type="password" name="pass" id="c_pass" className="shadow-none br-2" placeholder="New Password" />
						    </Form.Group>
						    <Form.Group className="mb-3">
			        			<Form.Label>Confirm Password</Form.Label>
						    	<Form.Control type="password" name="cpass" id="c_cpass" className="shadow-none br-2" placeholder="Confirm Password" />
						    </Form.Group>
						    <div  className="d-grid">
						    {
						    	changePassLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
						    </div>
			        	</Form>
			        </Modal.Body>
			        <Modal.Footer></Modal.Footer>
			    </Modal>
			</Row>
		)
}