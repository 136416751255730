import React, { useContext } from 'react';


import { AppContext } from '../../../App';


export const DashMenu=(props)=>{
	const { user, setLoad, setGetLoad, getCall, postCall, page, setPage, setConCat } = useContext(AppContext);
	
	return(
			<div className="dash-menu bg-greay-1 bdr-1b d-flex al-center px-0">				
				<div onClick={()=>{
					setPage('c-cat');
					getCall(setLoad,'materials/get/c/categories/',setConCat);
				}} className={page==='c-cat'?'dash-menu-item bdr-1r py-1 px-2 active':'dash-menu-item bdr-1r py-1 px-2'}>
					<div className="d-grid just-center">
						<i className="bi bi-text-indent-left"></i>
					</div>
					<span className="f12 f200 text-center d-grid">Category</span>
				</div>
				<div onClick={()=>{
					setPage('c-item');
				}} className={page==='c-item'?'dash-menu-item bdr-1r py-1 px-2 active':'dash-menu-item bdr-1r py-1 px-2'}>
					<div className="d-grid just-center">
						<i className="bi bi-screwdriver"></i>
					</div>
					<span className="f12 f200 text-center d-grid">Items</span>
				</div>
			</div>
		)
}