import React, { useContext} from 'react';
import {Container, Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const Authentication=()=>{
	const { link, loginError, setLoginError, load, setLoad, AuthCheck } = useContext(AppContext);	
	const requestUser=(event)=>{
		setLoad(true);
		event.preventDefault();
		if (event.target.username.value!==""&&event.target.password.value!=="") {
		axios.post(`${link}api/token/`,{
	   		username : 'ctc_'+event.target.username.value.toLowerCase(),
	   		password : event.target.password.value,
	   	}).then(function(response){
	   		if(response.status === 200){
	   			AuthCheck(response.data.access);	   			
	   		}else{
	   			setLoad(false);
	   			setLoginError(true);
	   			setTimeout(()=>{setLoginError(false)},5000);
	   		}
	   	}).catch(function (err){
	   		setLoad(false);
	   		setLoginError(true);
	   		setTimeout(()=>{setLoginError(false)},5000);
	   		if (err!==undefined) {
	   			console.log('Invalid credentials!');
	   		}else{
		        $.dialog({
		          title: "Can't reach server!",
		          content: 'Please check internet connection!',
		        });
		      }
	   	});
		}
	}


	return (
		<Container className="login-wrapper" >
			<Row className="login-window br4 px-2 py-5 bg-1" >
				<Col>
					<div className="py-2 px-3 grid-center" >

						{
							loginError!==undefined&&loginError?(
								<Alert variant="warning" className="t-red br-3" >Invalid credentials!</Alert>
							):''
						}
						<Form onSubmit={requestUser} className="login-form" >
					      <Form.Group className="mb-3 text-white">
					        <Form.Control type="text" name="username" required className="shadow-none" placeholder="Username" />
					      </Form.Group>
					      <Form.Group className="mb-3  text-white">
					        <Form.Control type="password" name="password" required className="shadow-none"  placeholder="Password" />
					      </Form.Group>
					      <div className="d-grid gap-2">
					      {
					      	load?(
						      	<Button variant="outline-primary" disabled>
						      	 <span><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						      ):(
						      	<Button type="submit" className="btn-t1 br2 f-400 f18 py-1" >LOGIN</Button>
						      )
						  }
						  </div>
				    	</Form>
					</div>
				</Col>
			</Row>
		</Container>
		);
}