import React, {useState, useContext, useEffect} from 'react';
import { Spinner, Button, Modal, Form, Row, Col, Container, Table } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const ActiveProject=()=>{
	const { setPage, postCall, getLoad, load, users, setUsers, userPerm, setProjects, proItem, setProItem, activePro, availMt, setAvailMt, consume,
	getDate, setProHisItem } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ mtSResult, setMtSResult ] = useState({});
	const mtSearch=(key)=>{
		if (key!=="") {
			setSearchIndex(prev=>0);
			if(availMt!==undefined){
			    if(Object.keys(availMt).length>0){
			    	setMtSResult(prev=>{});
			    	let count=0;
			    	Object.keys(availMt).map(item=>{
			    		if (count<12) {
				    		if (availMt[item].name.toLowerCase().includes(key.toLowerCase())||availMt[item].unique_code.toLowerCase().includes(key.toLowerCase())) {
				    			setMtSResult(prev=>({...prev,[item]:availMt[item]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{
			setMtSResult(prev=>({}));
		}
	}
	const [searchIndex,setSearchIndex]=useState(0);
	$('#search').off().on("keydown", function (e) {
		if (mtSResult!==undefined&&Object.keys(mtSResult).length>0) {
		    var code = e.which || e.keyCode;
		    if (code === 40) {
		    	e.preventDefault();
		    	if (searchIndex<Object.keys(mtSResult).length-1) {
		    		setSearchIndex(prev=>prev+1);
		    	}
		    }else if(code === 38){
		    	e.preventDefault();
		    	if (searchIndex>0) {
		    		setSearchIndex(prev=>prev-1);
		    	}	    	
		    }else if(code === 13){
		    	e.preventDefault();
		    	$('#mts_'+searchIndex).trigger('click');
		    }
		}
	});
	const [ addMtLoad, setAddMtLoad ] = useState(false);
	const searchItemlick=(mid)=>{
		if (mid!=="") {
			if (availMt!==undefined&&availMt[mid]!==undefined) {
				$('#search').val('');
				setAddMtLoad(true);
				setMtSResult(prev=>({}));
				postCall('projects/project/add/m/item/',{p_id:activePro.substring(1),m_id:mid.substring(1)}).then(res=>{
					setProItem(res.data.project);
					setAvailMt(res.data.mt);
					setAddMtLoad(false);
				}).catch(err=>{
					setAddMtLoad(false);
				})
			}
		}
	}

	const [ cSResult, setCSResult ] = useState({});
	const cSearch=(key)=>{
		if (key!=="") {
			setSearchIndex(prev=>0);
			if(consume!==undefined){
			    if(Object.keys(consume).length>0){
			    	setCSResult(prev=>{});
			    	let count=0;
			    	Object.keys(consume).map(item=>{
			    		if (count<12) {
				    		if (consume[item].name.toLowerCase().includes(key.toLowerCase())||consume[item].unique_code.toLowerCase().includes(key.toLowerCase())) {
				    			setCSResult(prev=>({...prev,[item]:consume[item]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{
			setCSResult(prev=>({}));
		}
	}
	const [csearchIndex,setCSearchIndex]=useState(0);
	$('#c_search').off().on("keydown", function (e) {
		if (cSResult!==undefined&&Object.keys(cSResult).length>0) {
		    var code = e.which || e.keyCode;
		    if (code === 40) {
		    	e.preventDefault();
		    	if (csearchIndex<Object.keys(cSResult).length-1) {
		    		setCSearchIndex(prev=>prev+1);
		    	}
		    }else if(code === 38){
		    	e.preventDefault();
		    	if (csearchIndex>0) {
		    		setCSearchIndex(prev=>prev-1);
		    	}	    	
		    }else if(code === 13){
		    	e.preventDefault();
		    	$('#con_'+csearchIndex).trigger('click');
		    }
		}
	});
	const [ addCLoad, setAddCLoad ] = useState(false);
	const [ activeConsId, setActiveConsId ] = useState();
	const [ cdate, setCdate ] = useState(getDate());
	const [ cqty, setCqty ] = useState();
	const [ upConLoad, setUpConLoad ] = useState(false);
	const csearchItemlick=(cid)=>{
		if (cid!=="") {
			if (consume!==undefined&&consume[cid]!==undefined) {
				setActiveConsId(cid);
				$('#c_search').val(consume[cid].name+' / '+consume[cid].unique_code);
				setCSResult(prev=>({}));
				$('#con_date').focus();
			}
		}
	}
	$('#con_date').off().on("keydown",function(e){
		let code = e.which || e.keyCode;
	    if(code === 13){
	    	e.preventDefault();
	    	$('#con_qty').focus();
	    }
	});
	$('#con_qty').off().on("keydown",function(e){
		let code = e.which || e.keyCode;
	    if(code === 13){
	    	e.preventDefault();
	    	$('#up_btn').focus();
	    }
	});
	const [ cdelLoad, setCdelLoad ] = useState(false);
	const [ cdelId, setCdelId ] = useState();
	const [ mtRetLoad, setMtRetLoad ] = useState(false);
	const [ mtRetId, setMtRetId ] = useState();
	const confirmReturn=(pid,ret_id,status)=>{
		if (pid!==""&&ret_id!==""&&status!=="") {
			setMtRetLoad(true);
			setMtRetId(ret_id);
			postCall('projects/project/return/m/item',{p_id:pid.substring(1),m_id:ret_id.substring(1),status:status}).then(res=>{
				setMtRetLoad(false);
				setMtRetId();
				setProItem(res.data.project);
				setAvailMt(res.data.mt);
			}).catch(err=>{
				setMtRetLoad(false);
				setMtRetId();
			})
		}
	}
	const [ proCLoad, setProCLoad ] = useState(false);
	return(
			<Row className="dash-content" >
				<Col xs={12} sm={12} md={12} className="dash-body">
						<Row className="dash-body-head bdr-1b px-1 bg1 mb-3">
							<Col xs={12} sm={12} md={6} className="flex-container gap-1 al-center py-2 tc-blue" >
								<Button onClick={()=>{setPage('project')}} className="btn-t1 br3" size="sm" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
								{
									load?(
										<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
									):(
										proItem!==undefined?proItem.project.name:'Something went wrong!'
									)
								}
							</Col>
							<Col xs={12} sm={12} md={6} className="d-flex just-end al-center" >
								{
									proItem!==undefined&&Object.keys(proItem.machine).length<=0?(
										proCLoad?(
											<Button className="btn-t1 br3" size="sm" ><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please Wait...</span></Button>
										):(
											<Button onClick={()=>{
												if (activePro!==undefined) {
													$.confirm({
													    title: 'Confirm!',
													    type:'blue',
													    content: 'Are you sure to complete the project?',
													    buttons: {
													        confirm:{
													        	text: 'Confirm',
													        	btnClass:'btn-green', 
													        	keys: ['enter'],
													            action: function(){
													                setProCLoad(true);
																	postCall('projects/project/completed/',{p_id:activePro.substring(1)}).then(res=>{
																		setProCLoad(false);
																		setProHisItem(res.data);
																		setPage('project-history-item');
																	}).catch(err=>{
																		setProCLoad(false);
																	});
													            }
													        },
													        cancel: function () {
													        },
													    }
													});
													
												}
											}} className="btn-t1 br3" size="sm" ><i className="bi bi-check2-square"></i> Project Completed</Button>
										)
									):''
								}
							</Col>
						</Row>
						<Container fluid>
							<Row className="dash-body-content">
								<Col xs={12} sm={12} md={12} className="bdr-2 br3 mb-3" >
									<Row className="py-1 bg2 bdr-2b al-center" >
										<Col xs={12} sm={12} md={4} lg={4}>
											<span className="f18" >Machine & Tools Used</span>
										</Col>
										<Col xs={12} sm={12} md={8} lg={8}>
											<div className="search-div pos-rel">
												{
													addMtLoad?(
														<Form.Control type="text" className="shadow-none d-grid br3" placeholder="Please wait..." disabled/>
													):(
														<Form.Control id="search" autocomplete="off" onBlur={()=>{setTimeout(()=>{setMtSResult(prev=>{})},200) }} onChange={(e)=>{mtSearch(e.target.value)}} type="text" name="search" className="shadow-none d-grid bdr-2 br3" placeholder="Search by Name or Code..." />
													)
												}
												{
						        					mtSResult!==undefined&&Object.keys(mtSResult).length>0?(
						        						<div className="search-results bdr-1">
						        							<div className="d-flex just-between al-center py-1 px-2 bdr-1b bg1">
						        								<span className="tc2 14 tc-blue" >Search results</span>
						        								<div className="d-flex just-end">
						        									<Button onClick={()=>{setMtSResult(prev=>{})}} variant="secondary" size="sm" ><i className="bi bi-x-lg"></i></Button>
						        								</div>
						        							</div>
						        							{
						        								Object.keys(mtSResult).map((item,i)=>(
						        									<div onClick={()=>{
						        										searchItemlick(item);
						        									}} id={`mts_${i}`} key={`mts_${i}`} className={ searchIndex!==undefined&&i===searchIndex?'sresultItem d-grid py-1 px-2 bdr-1b active':'sresultItem d-grid py-1 px-2 bdr-1b'}>
						        										<div className="">
						        											<span className="d-grid tc2" >{mtSResult[item].name} / {mtSResult[item].unique_code}</span>
						        											<span className="d-grid f14 tc3" >{mtSResult[item].manufacturer_name}, {mtSResult[item].category}, {mtSResult[item].sub_category}</span>
						        										</div>
						        										<span className="d-flex just-end tc2" ></span>
						        									</div>
						        								))
						        							}
						        						</div>
						        					):''
						        				}						        				
						        			</div>
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} md={12} className="p-2" >
										<span className="py-2 bg1 d-grid bdr-1 px-2" >Currently using machine & tools</span>
										<Table bordered className="tbl-bg-blue mb-3" >
										    <thead>
										        <tr className="tc1" >
										          <th>Machine</th>
										          <th>Start Date</th>
										          <th className="text-end" >Days</th>
										          <th className="text-end" >Options</th>
										        </tr>
										    </thead>
										    <tbody>
												{
													proItem!==undefined&&Object.keys(proItem.machine).length>0?(
														Object.keys(proItem.machine).map((item,i)=>(
															<tr key={`mac_${i}`} className="tc1"  >
																<td>
																	<span className="d-grid f18" >{proItem.machine[item].item} / {proItem.machine[item].code}</span>
																	<span className="f14 tc2" >{proItem.machine[item].manufacturer}, {proItem.machine[item].category}, {proItem.machine[item].sub_category}</span>
																</td>
																<td>{proItem.machine[item].entry_date}</td>
																<td className="text-end" >{proItem.machine[item].days}</td>
																<td className="text-end" >
																	<div className="d-grid gap-1 just-end">
																	{
																		mtRetId!==undefined&&mtRetId===item&&mtRetLoad?(
																			<Button className="btn-t1 br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Wait...</span></Button>
																		):(

																			<Button onClick={()=>{
																				if (activePro!==undefined) {
																					$.confirm({
																					    title: 'Confirm!',
																					    type:'blue',
																					    content: 'Please click the bellow options!',
																					    buttons: {
																					        confirm:{
																					        	text: 'Normal Return',
																					        	btnClass:'btn-green', 
																					        	keys: ['enter'],
																					            action: function(){
																					                confirmReturn(activePro,item,'i');
																					            }
																					        },
																					        damage: {
																					            text: 'Damage',
																					            btnClass:'btn-warning',
																					            action: function(){
																					                confirmReturn(activePro,item,'r');
																					            }
																					        },
																					        lost: {
																					            text: 'Lost',
																					            btnClass:'btn-red',
																					            action: function(){
																					                confirmReturn(activePro,item,'l');
																					            }
																					        },
																					        cancel: function () {
																					        },
																					    }
																					});
																				}
																			}} className="btn-t1 br3" size="sm" ><i className="bi bi-arrow-90deg-left"></i> Return</Button>
																		)
																	}
																	</div>
																</td>
															</tr>
														))
													):'Machine & Tools Not using right now!'
												}
											</tbody>
							        	</Table>
							        	<span className="py-2 bg1 d-grid bdr-1 px-2" >History</span>
										<Table bordered className="tbl-bg-blue mb-3" >
										    <thead>
										        <tr className="tc1" >
										          <th>Machine</th>
										          <th>Start Date</th>
										          <th>End Date</th>
										          <th className="text-end" >Days Used</th>
										        </tr>
										    </thead>
										    <tbody>
												{
													proItem!==undefined&&Object.keys(proItem.history).length>0?(
														Object.keys(proItem.history).map((item,i)=>(
															<tr key={`mac_${i}`} className="tc1"  >
																<td>
																	<span className="d-grid f18" >{proItem.history[item].item} / {proItem.history[item].code}</span>
																	<span className="f14 tc2" >{proItem.history[item].manufacturer}, {proItem.history[item].category}, {proItem.history[item].sub_category}</span>
																</td>
																<td>{proItem.history[item].entry_date}</td>
																<td>{proItem.history[item].end_date}</td>
																<td className="text-end" >{proItem.history[item].days}</td>
															</tr>
														))
													):(<span className="p-2" >Machine & Tools Not used yet!</span>)
												}
											</tbody>
							        	</Table>
										</Col>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} className="bdr-2 br3" >
									<Row className="py-1 bg2 bdr-2b al-center" >
										<Col xs={12} sm={12} md={3}>
											<span className="f18" >Consumbles Used</span>
										</Col>
										<Col xs={12} sm={12} md={4}>
											<div className="search-div pos-rel">
												{
													addCLoad?(
														<Form.Control type="text" className="shadow-none d-grid br3" placeholder="Please wait..." disabled/>
													):(
														<Form.Control id="c_search" autocomplete="off" onBlur={()=>{setTimeout(()=>{setCSResult(prev=>{})},200) }} onChange={(e)=>{cSearch(e.target.value)}} type="text" name="search" className="shadow-none d-grid bdr-2 br3" placeholder="Search by Name or Code..." />
													)
												}
												{
						        					cSResult!==undefined&&Object.keys(cSResult).length>0?(
						        						<div className="search-results bdr-1">
						        							<div className="d-flex just-between al-center py-1 px-2 bdr-1b bg1">
						        								<span className="tc2 14 tc-blue" >Search results</span>
						        								<div className="d-flex just-end">
						        									<Button onClick={()=>{setCSResult(prev=>{})}} variant="secondary" size="sm" ><i className="bi bi-x-lg"></i></Button>
						        								</div>
						        							</div>
						        							{
						        								Object.keys(cSResult).map((item,i)=>(
						        									<div onClick={()=>{
						        										csearchItemlick(item);
						        									}} id={`con_${i}`} key={`con_${i}`} className={ csearchIndex!==undefined&&i===csearchIndex?'sresultItem d-grid py-1 px-2 bdr-1b active':'sresultItem d-grid py-1 px-2 bdr-1b'}>
						        										<div className="">
						        											<span className="d-grid tc2" >{cSResult[item].name} / {cSResult[item].unique_code}</span>
						        											<span className="d-grid f14 tc3" ></span>
						        										</div>
						        										<span className="d-flex just-end tc2" ></span>
						        									</div>
						        								))
						        							}
						        						</div>
						        					):''
						        				}						        				
						        			</div>
										</Col>
										<Col xs={12} sm={12} md={2}>
											<Form.Control type="date" onChange={(e)=>{
												setCdate(e.target.value);
											}} id="con_date" defaultValue={getDate()} placeholder="Date" className="shadow-none bdr-2 br3" />
										</Col>
										<Col xs={12} sm={12} md={2}>
											<Form.Control onChange={(e)=>{
												setCqty(e.target.value);
											}} type="number" id="con_qty" placeholder="Qty"  className="shadow-none bdr-2 br3" />
										</Col>
										<Col xs={12} sm={12} md={1} className="d-grid">
											{
												upConLoad!==undefined&&upConLoad?(
													<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Wait...</span>
												):(
													<Button onClick={()=>{
														if (activeConsId!==undefined&&cdate!==undefined&&cqty!==undefined&&activePro!==undefined) {
															setUpConLoad(true);
															postCall('projects/project/add/c/item/',{p_id:activePro.substring(1),c_id:activeConsId.substring(1),
															qty:cqty,date:cdate}).then(res=>{
																setUpConLoad(false);
																setProItem(res.data.project);
																setAvailMt(res.data.mt);
																setCqty();
																setActiveConsId();
																$('#con_qty').val('');
																$('#c_search').val('');
															}).catch(err=>{
																setUpConLoad(false);
															})
														}
													}} className="btn-t1 br3 d-grid" id="up_btn" >UPDATE</Button>
												)
											}
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} md={12} className="py-2" >
											<span className="py-2 bg1 d-grid bdr-1 px-2" >History</span>
											<Table bordered className="tbl-bg-blue mb-3" >
											    <thead>
											        <tr className="tc1" >
											          <th>Date</th>
											          <th>Consumbles</th>
											          <th>Unit</th>
											          <th>Quantity</th>
											          <th className="text-end" >Options</th>
											        </tr>
											    </thead>
											    <tbody>
													{
														proItem!==undefined&&Object.keys(proItem.consum).length>0?(
															Object.keys(proItem.consum).map((item,i)=>(
																<tr key={`mac_${i}`} className="tc1"  >
																	<td>{proItem.consum[item].use_date}</td>
																	<td>
																		<span className="d-grid f18" >{proItem.consum[item].item} / {proItem.consum[item].code}</span>
																		<span className="f14 tc2" >{proItem.consum[item].category}</span>
																	</td>
																	<td>{proItem.consum[item].unit}</td>
																	<td>{proItem.consum[item].qty}</td>
																	<td className="text-end" >
																		<div className="d-flex just-end">
																		{
																			cdelLoad&&cdelId===item?(
																			<Button variant="outline-danger" className="br3" size="sm"  disabled>
																				<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Wait...</span>
																			</Button>
																			):(
																				<Button onClick={()=>{
																					if (activePro!==undefined) {
																						setCdelLoad(true);
																						setCdelId(item);
																						postCall('projects/project/delete/c/item/',{p_id:activePro.substring(1),pc_id:item.substring(1)}).then(res=>{
																							setCdelLoad(false);
																							setProItem(res.data.project);
																							setAvailMt(res.data.mt);
																							setCdelId();
																						}).catch(err=>{
																							setCdelLoad(false);
																							setCdelId();
																						});
																					}
																				}} variant="outline-danger" className="br3" size="sm"  ><i className="bi bi-trash"></i> Delete</Button>
																			)
																		}
																		</div>
																	</td>
																</tr>
															))
														):(<span className="p-2" >Consumables Not used yet!</span>)
													}
												</tbody>
								        	</Table>
										</Col>
									</Row>
								</Col> 
							</Row>
						</Container>
				</Col>
			</Row>
		)
}