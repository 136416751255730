import React, { useContext} from 'react';
import { Row } from 'react-bootstrap';
import { AppContext } from '../../App';

export const Home=()=>{
	const {  } = useContext(AppContext);

	return(
		<Row className="basic-wrapper">
			<h2>Home Page</h2>
		</Row>
	)
}