import React, { useContext } from 'react';


import { AppContext } from '../../../App';


export const DashMenu=(props)=>{
	const { user, setLoad, getCall, page, setPage, setProHistory } = useContext(AppContext);
	
	return(
			<div className="dash-menu bg-greay-1 bdr-1b d-flex al-center px-0">				
				<div onClick={()=>{
					setPage('project');
				}} className={page==='project'?'dash-menu-item bdr-1r py-1 px-2 active':'dash-menu-item bdr-1r py-1 px-2'}>
					<div className="d-grid just-center">
						<i className="bi bi-app-indicator"></i>
					</div>
					<span className="f12 f200 text-center d-grid">Ongoing projects</span>
				</div>
				<div onClick={()=>{
					setPage('pro-history');
					getCall(setLoad,'projects/get/history/projects/',setProHistory);
				}} className={page==='pro-history'?'dash-menu-item bdr-1r py-1 px-2 active':'dash-menu-item bdr-1r py-1 px-2'}>
					<div className="d-grid just-center">
						<i className="bi bi-clock-history"></i>
					</div>
					<span className="f12 f200 text-center d-grid">History</span>
				</div>
			</div>
		)
}