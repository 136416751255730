import React, {useState, useContext, useEffect} from 'react';
import { Spinner, Button, Modal, Form, Row, Col, Pagination } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const MachineTools=()=>{
	const { postCall, load, mtCat, mt, setMt } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ editId, setEditId ] = useState();
	const [ editable, setEditable ] = useState(false);
	const updateItem=(e)=>{
		e.preventDefault();
		let name = e.target.p_name.value;
		let code = e.target.p_code.value;
		let cat = e.target.p_cat.value;
		let sub = e.target.p_sub_cat.value;
		let serial = e.target.p_serial.value;
		let manuf = e.target.p_manuf.value;
		let pdate = e.target.p_date.value;
		let other_det = e.target.p_other_det.value;
		if (editable&&editId!==undefined) {
			if (name!==""&&cat!==""&&sub!=="") {
				setUpdateLoad(true);
				postCall('materials/edit/m/item/',{i_id:editId.substring(1), name:name,unique_code:code,c_id:cat,s_id:sub,serial_number:serial,
					manufacturer_name:manuf,purchase_date:pdate,other_details:other_det}).then(res=>{
					if (res.data.status) {
						setMt(prev=>({...prev,[res.data.data.id]:res.data.data.data}));
						setUpdateModal(false);
					}else{
						$.dialog({
			              title: "Something went wrong!",
			              content: res.data.message,
			              type:'red'
			            });
					}				
					setUpdateLoad(false);				
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}else{
			if (validCode===undefined||validCode) {
				$.dialog({
	              title: "Invalid Code!",
	              content: 'Please enter a valid code!',
	              type:'red'
	            });
			}
			if (name!==""&&cat!==""&&sub!==""&&!validCode&&validCode!==undefined) {
				setUpdateLoad(true);
				postCall('materials/add/m/item/',{name:name,code:code,cat:cat,sub_cat:sub,serial:serial,manuf:manuf,p_date:pdate,other_det:other_det}).then(res=>{
					if (res.data.status) {
						setMt(prev=>({...prev,[res.data.data.id]:res.data.data.data}));
						setUpdateModal(false);
					}else{
						$.dialog({
			              title: "Something went wrong!",
			              content: res.data.message,
			              type:'red'
			            });
					}				
					setUpdateLoad(false);				
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}

	}
	const [ catId, setCatId ] = useState();
	const [ validCode, setValidCode ] = useState();
	const [ checkCodeLoad, setCheckCodeLoad ] = useState(false);
	useEffect(()=>{
		if (!updateModal) {
			setValidCode();
			setEditId();
			setEditable(false);
		}
	},[updateModal]);
	const [ statChangeLoad, setStatChangeLoad ] = useState(false);
	const [ statId, setStatId ] = useState();
	const [ statModal, setStatModal ] = useState(false);
	const changeItemStat=(stat)=>{
		if (stat!==""&&statId!==undefined) {			
			setStatChangeLoad(true);
			setStatModal(false);
			postCall('materials/status/m/item/',{i_id:statId.substring(1),status:stat}).then(res=>{
				setStatChangeLoad(false);
				if(res.data.status){
					setMt(prev=>({...prev,[res.data.data.id]:res.data.data.data}));
				}
				setStatId();
			}).catch(err=>{
				setStatChangeLoad(false);
				setStatId();
			})
		}
	}
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body">
					<Row className="dash-body-head bdr-1b px-1 bg1">
						<Col className="d-grid al-center py-2 tc-blue" >
							{
								load?(
									<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
								):'Items'
							}
						</Col>
						<Col className="d-flex just-end al-center gap-1">
							<Button onClick={()=>{setUpdateModal(true);}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New Item</Button>
						</Col>
					</Row>
					<Row className="dash-body-content">
						<Col>
							{
								mt!==undefined&&Object.keys(mt).length>0?(
									Object.keys(mt).map((item,i)=>(
										<Row key={`mach_${i}`} className="bdr-1b py-2" >
											<Col xs={12} sm={12} md={6} >
												<span className="d-flex gap-1" >{mt[item].name} / {mt[item].unique_code} {
													mt[item].status==='i'?(
														<span className="stat-green px-2 br3" >Idle</span>
													):mt[item].status==='r'?(
														<span className="stat-oranage px-2 br3" >Repairs</span>
													):mt[item].status==='u'?(
														<span className="stat-blue px-2 br3" >In use</span>
													):mt[item].status==='l'?(
														<span className="stat-red px-2 br3" >Lost</span>
													):mt[item].status==='d'?(
														<span className="stat-black px-2 br3" >Dead</span>
													):''
												}</span>
												<span className="f14 tc2" >{mtCat!==undefined?mtCat[mt[item].category].name:''} / {mtCat!==undefined?mtCat[mt[item].category].sub_category[mt[item].sub_category].name:''}</span>
											</Col>
											<Col xs={12} sm={12} md={6} className="flex-container just-end al-center" >
												{
													statId!==undefined&&statId===item&&statChangeLoad?(
														<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
													):(
														mt[item].status!==''&&mt[item].status!=='u'?(
															<Button onClick={()=>{
																setStatModal(true);
																setStatId(item);
															}} className="btn-t1 br3" size="sm"><i className="bi bi-shuffle"></i> Change Status</Button>
														):''
													)
												}
												<Button onClick={()=>{
													setEditable(true);
													setEditId(item);
													setUpdateModal(true);
													setCatId(mt[item].category);
												}} className="btn-t1 br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
											</Col>
										</Row>
									))
								):'Not found'
							}
						</Col>
					</Row>
				</Col>
				<Modal size="xl" fullscreen="lg-down" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        	<Modal.Header closeButton>Add new</Modal.Header>
		        	<Modal.Body>
			        	<div className="bdr-1 mb-2 bg-greay-1 p-2 br3" >
				        	<Form onSubmit={updateItem} id="purchase_add">
				        		<Row>
				        			<Col xs={12} sm={12} md={8}>
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Product name *</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text" defaultValue={editable&&editId!==undefined?mt[editId].name:''}  name="p_name" className="shadow-none br3" required/>
						        			</div>
						        		</Form.Group>
						        	</Col>
						        	<Col xs={12} sm={12} md={4} >				        		
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Unique Code*
						        			{
				        						checkCodeLoad?(
				        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
				        						):(
						        					validCode!==undefined?(
						        						validCode?(
						        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
						        						):(
						        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
						        						)
						        					):''
					        					)
				        					}
						        			</Form.Label>
						        			<div className="search-div pos-rel">
						        			{
						        				editable&&editId!==undefined?(
						        					<Form.Control type="text" defaultValue={mt[editId].unique_code}  name="p_code" className="shadow-none br3" disabled/>
						        				):(
							        				<Form.Control onBlur={(e)=>{
							        					let code = e.target.value;
							        					if (code!=="") {
								        					setCheckCodeLoad(true);
								        					postCall('materials/m/check/item/code/',{code:code}).then(res=>{
								        						setValidCode(res.data);
								        						setCheckCodeLoad(false);
								        					}).catch(err=>{
								        						setCheckCodeLoad(false);
								        					});
								        				}else{
								        					setValidCode();
								        				}
							        				}} type="text"  name="p_code" className="shadow-none br3" required/>
						        				)
						        			}						        				
						        			</div>
						        		</Form.Group>
					        		</Col>
				        		</Row>
				        		<Row>
				        			<Col xs={12} sm={12} md={6} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Category*</Form.Label>
						        			<Form.Select name="p_cat" defaultValue={editable&&editId!==undefined?mt[editId].category:''} onChange={(e)=>{setCatId(e.target.value);}} className="shadow-none br3" required>
					        					<option value="">Choose Category</option>
						        				{
						        					mtCat!==undefined&&Object.keys(mtCat).length>0?(
						        						Object.keys(mtCat).map((item,i)=>(
						        							<option value={item}  key={`categ_${i}`}>{mtCat[item].name}</option>
						        						))
						        					):''													
						        				}
											</Form.Select>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={6} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Sub Category*</Form.Label>
						        			<Form.Select defaultValue={editable&&editId!==undefined?mt[editId].sub_category:''} name="p_sub_cat" className="shadow-none br3" required>
					        					<option value="">Choose Sub Category</option>
						        				{
						        					catId!==undefined&&catId!==""&&Object.keys(mtCat[catId].sub_category).length>0?(
						        						Object.keys(mtCat[catId].sub_category).map((item,i)=>(
						        							<option value={item}  key={`categ_${i}`}>{mtCat[catId].sub_category[item].name}</option>
						        						))
						        					):''													
						        				}
											</Form.Select>
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label  className="tc2" >Serial number</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control defaultValue={editable&&editId!==undefined?mt[editId].serial_number:''} type="text"  name="p_serial" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Manufacturer</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text" defaultValue={editable&&editId!==undefined?mt[editId].manufacturer_name:''}  name="p_manuf" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Purchase Date</Form.Label>
					        				<Form.Control defaultValue={editable&&editId!==undefined?mt[editId].purchase_date:''} name="p_date" type="date" className="shadow-none br3" />
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Other Details</Form.Label>
						        			<Form.Control defaultValue={editable&&editId!==undefined?mt[editId].other_details:''} type="text" as="textarea" name="p_other_det" rows="5" className="shadow-none br3"  />
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
					        			<Form.Group className="mb-3 d-grid al-center">			        		
							        		{
										    	updateLoad?(
										    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
										    	):(
										    	<Button type="submit" id="add_btn" className="btn-t1 br3" ><i className="bi bi-plus-lg"></i> Add Item</Button>
										    	)
										    }
									    </Form.Group>
					        		</Col>
				        		</Row>
				        		
			        		</Form>
		        		</div>
		        	</Modal.Body>
		        	<Modal.Footer></Modal.Footer>
        		</Modal>
        		<Modal size="md" fullscreen="lg-down" show={statModal} backdrop="static" onHide={()=>{setStatModal(false)}}>
		        	<Modal.Header closeButton>Change Status</Modal.Header>
		        	<Modal.Body>
			        	<div className="flex-container gap-1 al-center">
			        		<span onClick={()=>{changeItemStat('i')}} className="stat-green px-4 py-3 br3 cpoint" >Idle</span>
							<span onClick={()=>{changeItemStat('r')}} className="stat-oranage px-4 py-3 br3 cpoint" >Repairs</span>
							<span onClick={()=>{changeItemStat('l')}} className="stat-red px-4 py-3 br3 cpoint" >Lost</span>
							<span onClick={()=>{changeItemStat('d')}} className="stat-black px-4 py-3 br3 cpoint" >Dead</span>
			        	</div>
		        	</Modal.Body>
		        	<Modal.Footer></Modal.Footer>
        		</Modal>
			</Row>
		)
}