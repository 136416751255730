import React, {useState, createContext, useEffect} from 'react';
import { Container, Row } from 'react-bootstrap';
import axios from "axios";
import {LocalServer} from './templates/WebIp';
import { Menu } from './templates/menu';
import { Dashboard } from './templates/dashboard';
import { Authentication } from './templates/authentication';
import $ from 'jquery';
import 'jquery-confirm';
export const AppContext = createContext();


function App() {
  var link = LocalServer();
  const [ page, setPage ] = useState('home');
  const [ token, setToken ] = useState();
  const [ user, setUser ] = useState();
  const [ users, setUsers ] = useState();
  const [ userPerm, setUserPerm ] = useState();
  const [ load, setLoad ] = useState(false);
  const [ getLoad, setGetLoad ] = useState(false);
  const [ loginError, setLoginError ] = useState(false);
  const [ mt, setMt ] = useState({});
  const [ mtCat, setMtCat ] = useState();
  const [ consume, setCounsume ] = useState({});
  const [ conCat, setConCat ] = useState();
  const [ projects, setProjects ] = useState();
  const [ proItem, setProItem ] = useState();
  const [ activePro, setActivePro ] = useState();
  const [ prjectHistory, setProjectHistory ] = useState();
  const [ availMt, setAvailMt ] = useState();
  const [ proHistory, setProHistory ] = useState();
  const [ proHisItem, setProHisItem ] = useState();


  const AuthCheck=(tok)=>{
    axios.get(`${link}api/auth/check/`,{
      headers:{
              Authorization: 'Bearer '+tok
            }
    }).then(response=>{
      setLoad(false);
      setToken(tok);
      setUser(response.data);
    }).catch(err=>{
      setLoad(false);
      setLoginError(true);
        setTimeout(()=>{setLoginError(false)},5000);
        setToken();
        if (err!==undefined) {
        if(err.response.status === 401){
              console.log('Auth_check - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 404){
              console.log('Auth_check - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Auth_check - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Auth_check - ',err.response.status,':',err.response.data);
          }
      }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
        }
    });
    }
  useEffect(()=>{
    if (token!==undefined) {
      localStorage.setItem('access', JSON.stringify(token));
    }
  },[token]);
  useEffect(()=>{
    if (token===undefined) {
      if(localStorage.getItem('access') !== null && localStorage.getItem('access') !== undefined && localStorage.getItem('access') !== "" ){
        AuthCheck(JSON.parse(localStorage.getItem('access')));
      }
    }
  },[]);
  const getCall=(loadfn,path,setfn)=>{
    loadfn(true);
    axios.get(`${link}${path}`,{
          headers:{
            Authorization: 'Bearer '+token
          }
        }).then((response)=>{
          loadfn(false);
          setfn(response.data);
        }).catch((err)=>{
          loadfn(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setToken();
            }else if(err.response.status === 404){
                console.log(path, err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log(path, err.response.status,':',err.response.statusText);
            }else{
                console.log(path, err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
        }
    });
  }
  const postCall=(path,data)=>{
    return new Promise((resolve,reject)=>{
      axios.post(`${link}${path}`,data,{
            headers:{
              Authorization: 'Bearer '+token
            }
          }).then((response)=>{
            resolve({data:response.data});
          }).catch((err)=>{
            if (err!==undefined&&err.response!==undefined) {
              if(err.response.status === 401){
                  setToken();
              }else if(err.response.status === 404){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else if(err.response.status === 500){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else{
                  console.log(path, err.response.status,':',err.response.data);
              }
            }else{
              $.dialog({
                title: "Can't reach server!",
                content: 'Please check internet connection!',
              });
            }
            reject();
      });
    });
  }
  const postFile=(path,data)=>{
    return new Promise((resolve,reject)=>{
      axios.post(`${link}${path}`,data,{
            headers:{
              Authorization: 'Bearer '+token,
              "Content-Type": "multipart/form-data",
            }
          }).then((response)=>{
            resolve({data:response.data});
          }).catch((err)=>{
            if (err!==undefined&&err.response!==undefined) {
              if(err.response.status === 401){
                  setToken();
              }else if(err.response.status === 404){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else if(err.response.status === 500){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else{
                  console.log(path, err.response.status,':',err.response.data);
              }
            }else{
              $.dialog({
                title: "Can't reach server!",
                content: 'Please check internet connection!',
              });
            }
            reject();
      });
    });
  }
  function getDate(){
      let today = new Date();
      let x = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      return x;
  }
  return (
    <AppContext.Provider value={{ link, AuthCheck, getCall, postCall, postFile, page, setPage, token, setToken, user, load, setLoad, getLoad,
    setGetLoad, getDate, loginError, setLoginError, users, setUsers, userPerm, setUserPerm, mt, setMt, mtCat, setMtCat, conCat, setConCat, consume,
    setCounsume, projects, setProjects, proItem, setProItem, activePro, setActivePro, availMt, setAvailMt, proHistory, setProHistory, proHisItem,
    setProHisItem }} >
    {
      token !== undefined?(
        <Container fluid>
          <Row>
            <Menu />
            <Dashboard />
          </Row>
        </Container>        
      ):(
        <Authentication />
      )
    }      
    </AppContext.Provider>
  );
}

export default App;
