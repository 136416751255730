import React, {useState, useContext, useEffect} from 'react';
import { Alert, Spinner, Button, Modal, Form, Row, Col, Table, Pagination, Container } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const MachineToolsCategory=()=>{
	const { setPage, postCall, getLoad, load, mtCat, setMtCat } = useContext(AppContext);
	const [ catUpModal, setCatUpModal ] = useState(false);
	const [ catUpLoad, setCatUpLoad ] = useState(false);
	const [ editCatId, setEditCatId ] = useState();
	const [ catEditable, setCatEditable ] = useState(false);
	const updateCat=(e)=>{
		e.preventDefault();
		let name = e.target.cat.value;
		if (name!=="") {
			setCatUpLoad(true);
			if (editCatId!==undefined&&catEditable) {
				postCall('materials/edit/m/category/',{name:name, c_id:editCatId.substring(1)}).then(res=>{
					setMtCat(res.data);
					setCatUpLoad(false);
					setCatUpModal(false);
					setContent(Object.keys(res.data));
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}else{
				postCall('materials/add/m/category/',{name:name}).then(res=>{
					setMtCat(res.data);
					setCatUpLoad(false);
					setCatUpModal(false);
					setContent(Object.keys(res.data));
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}
		}
	}
	const changeCatStatus=(cid,status)=>{
		if (cid!==""&&status!=="") {
			setEditCatId(cid);
			setChangeStatusLoad(true);
			postCall('materials/status/m/category/',{c_id:cid.substring(1),status:status}).then(res=>{
				setChangeStatusLoad(false);
				setMtCat(res.data);
				setEditCatId();
			}).catch(err=>{
				setChangeStatusLoad(false);
				setEditCatId();
			})

		}
	}
	useEffect(()=>{
		if (!catUpModal) {
			setEditCatId();
			setCatEditable(false);
		}
	},[catUpModal])
	const [ subCatUpModal, setSubCatUpModal ] = useState(false);
	const [ catId, setCatId ] = useState();
	const [ subcId, setSubcId ] = useState();
	const [ subEditable, setSubEditable ] = useState(false);
	const updateSubCat=(e)=>{
		e.preventDefault();
		let name = e.target.subc.value;
		if (name!==""&&catId!==undefined) {
			setCatUpLoad(true);
			if (subEditable&&subcId!==undefined) {
				postCall('materials/edit/m/sub/category/',{name:name,s_id:subcId.substring(1)}).then(res=>{
					setMtCat(res.data);
					setCatUpLoad(false);
					setSubCatUpModal(false);
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}else{
				postCall('materials/add/m/sub/category/',{size:name,c_id:catId.substring(1)}).then(res=>{
					setMtCat(res.data);
					setCatUpLoad(false);
					setSubCatUpModal(false);
				}).catch(err=>{
					setCatUpLoad(false);
				});
			}
		}
	}
	useEffect(()=>{
		if (!subCatUpModal) {
			setCatId();
			setSubcId();
			setSubEditable(false);
		}
	},[subCatUpModal]);
	const [ activeItem, setActiveItem ] = useState();
	const [ changeStatusLoad, setChangeStatusLoad ] = useState(false);
	const changeStatus=(cid,status)=>{
		if (cid!==""&&status!=="") {
			setActiveItem(cid);
			setChangeStatusLoad(true);
			postCall('materials/status/m/sub/category/',{s_id:cid.substring(1),status:status}).then(res=>{
				setChangeStatusLoad(false);
				setActiveItem();
				setMtCat(res.data);
			}).catch(err=>{
				setChangeStatusLoad(false);
				setActiveItem();
			});
		}
	}

	// Default search...
	const [ content, setContent ] = useState();
	useEffect(()=>{
		if (mtCat!==undefined&&content===undefined) {
			setContent(Object.keys(mtCat));
		}
	},[mtCat]);
	const search=(key)=>{
		if (key!==""&&key!==undefined) {
			if(mtCat!==undefined){
			    if(Object.keys(mtCat).length>0){
			    	setContent(prev=>([]));
			    	Object.keys(mtCat).map(item=>{
			    		if (mtCat[item].name.toLowerCase().includes(key.toLowerCase())) {
			    			setContent(prev=>([...prev,item]));
			    		}
			    	});
			    }
			}
		}else{
			setContent(Object.keys(mtCat));
		}
	}
	// Pagination
	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState(0);
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=5;	
	// number of pages in pagination
	const pageCount = 10;
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body p-4">
					<Container className="bdr-1 br2" fluid>
						<Row className="dash-body-head bdr-1b px-1 bg1">
							<Col className="d-grid al-center py-2 tc-blue" >
								{
									load?(
										<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
									):'Categories'
								}
							</Col>
							<Col className="d-flex just-end al-center gap-1">
								<Button onClick={()=>{
									setCatUpModal(true);
								}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New Category</Button>
							</Col>
						</Row>
						<Row className="dash-body-content">
							<Col>
							{
								mtCat!==undefined&&Object.keys(mtCat).length>0&&content!==undefined?(
									content.length>0?(
										content.map((item,i)=>(
											activePage!==undefined&&i>=activePage*itemCount&&i<activePage*itemCount+itemCount?(
												<Row className="bdr-1b py-2" key={`cat_${i}`} >
													<Col xs={12} sm={12} md={12} >
														<Row>
															<Col xs={12} sm={12} md={6}>
																<span className="f18 tc-blue f200 d-flex al-end gap-1" >
																{
																	mtCat[item].status?(
																		<span className="sucess-tag f16 px-1 br3" ><i className="bi bi-check-circle-fill"></i></span>
																	):(
																		<span className="error-tag f16 px-1 br3" ><i className="bi bi-x-circle-fill"></i></span>
																	)
																}
																{ mtCat[item].name }</span>
															</Col>
															<Col xs={12} sm={12} md={6}>
																<div className="flex-container just-end gap-1">
																	<Button onClick={()=>{
																		setCatEditable(true);
																		setEditCatId(item);
																		setCatUpModal(true);
																	}} className="btn-t1 br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
																	{
																		editCatId!==undefined&&editCatId===item&&changeStatusLoad?(
																			<Button className="btn-t1 br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
																		):(
																			mtCat[item].status?(
																				<Button onClick={()=>{changeCatStatus(item,false)}} className="btn-disable br3 d-flex gap-1" size="sm" ><i className="bi bi-x-circle-fill d-flex al-center"></i> Disable</Button>																
																			):(																
																				<Button onClick={()=>{changeCatStatus(item,true)}} className="btn-enable br3 d-flex gap-1" size="sm" ><i className="bi bi-check-circle-fill d-flex al-center"></i> Activate</Button>
																			)
																		)
																	}
																</div>
															</Col>
														</Row>
													</Col>
													<Col xs={12} sm={12} md={12} >
														<Row className="d-grid mx-2 bdr-1 my-2 br3">
															<Col xs={12} sm={12} md={12} >
																<Row className="py-1 bg-greay-2 al-center bdr-1b" >
																	<Col>
																		<span className="tc-blue" >Sub Categories</span>
																	</Col>
																	<Col className="d-flex just-end al-center gap-1">
																		<Button onClick={()=>{
																			setCatId(item);
																			setSubCatUpModal(true);
																		}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New Sub Category</Button>
																	</Col>
																</Row>
															</Col>
															<Col xs={12} sm={12} md={12} id={`size_box${item}`} className="size-area">
																{
																	mtCat[item].sub_category!==undefined?(
																		Object.keys(mtCat[item].sub_category).length>0?(
																			Object.keys(mtCat[item].sub_category).map((subc,s_i)=>(
																				<Row className="grid-split-2 bdr-1b py-1" key={`subc_${s_i}`} >
																					<Col className="d-flex gap-1 al-center" >
																						{
																							mtCat[item].sub_category[subc].status?(
																								<span className="sucess-tag f16 px-1 br3" ><i className="bi bi-check-circle-fill"></i></span>
																							):(
																								<span className="error-tag f16 px-1 br3" ><i className="bi bi-x-circle-fill"></i></span>
																							)
																						}
																						<span className="f14 tc2 f200 d-flex al-end gap-1" >{mtCat[item].sub_category[subc].name}</span>
																					</Col>
																					<Col className="d-flex just-end al-center gap-1">
																						<Button onClick={()=>{
																							setCatId(item);
																							setSubEditable(true);
																							setSubcId(subc);
																							setSubCatUpModal(true);
																						}} className="btn-t1 br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
																						{
																							activeItem!==undefined&&activeItem===subc&&changeStatusLoad?(
																								<Button className="btn-t1 br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
																							):(
																								mtCat[item].sub_category[subc].status?(
																									<Button onClick={()=>{changeStatus(subc,false)}} className="btn-disable br3 d-flex gap-1" size="sm" ><i className="bi bi-x-circle-fill d-flex al-center"></i> Disable</Button>																
																								):(																
																									<Button onClick={()=>{changeStatus(subc,true)}} className="btn-enable br3 d-flex gap-1" size="sm" ><i className="bi bi-check-circle-fill d-flex al-center"></i> Activate</Button>
																								)
																							)
																						}
																					</Col>
																				</Row>
																			))
																		):(<Alert variant="info" className="py-1 px-2 m-3 br3" >Sub Category NOT found!</Alert>)
																	):(<Alert variant="warning" className="t-red py-1 px-2 m-3 br3" >Something went wrong!</Alert>)
																}
															</Col>
															<div onClick={()=>{
																$('#size_box'+item).slideToggle();
															}} className="d-grid py-1 cpoint just-center al-center bg-greay-2"><i className="bi bi-filter f20"></i></div>
														</Row>
													</Col>												
												</Row>
											):''
										))
									):'Result NOT Found'
								):'Category NOT Found! Please add category.'
							}
							</Col>
						</Row>
						<div className="d-grid just-center py-2">
							{
							    numPage!==undefined&&numPage>1?(
									<Pagination>
								      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
								      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
								      	{
								      		Array(numPage).fill('1').map((item,i)=>(
								      			i>=indexStart&&i<indexStart+pageCount?(
						      						activePage!==undefined&&activePage===i?(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
						      						):(
						      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
						      						)
						      					):''
						      				))
								      	}				      	
								      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
								      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
								    </Pagination>
								):''
							}
					    </div>
					</Container>
					
				</Col>
				<Modal size="md" show={catUpModal} backdrop="static" onHide={()=>{setCatUpModal(false)}}>
		        	<Modal.Header closeButton>Category</Modal.Header>		        	
			        <Modal.Body>
			        	<Form onSubmit={updateCat} >
						    <Form.Group className="mb-3">
			        			<Form.Label>Category Name</Form.Label>
						    	<Form.Control type="text" defaultValue={catEditable&&editCatId!==undefined?mtCat[editCatId].name:''} name="cat"  className="shadow-none br-2" required/>
						    </Form.Group>
						    <div  className="d-grid">
						    {
						    	catUpLoad?(
						    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    		<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
						    </div>
			        	</Form>
			        </Modal.Body>
			        <Modal.Footer></Modal.Footer>
			    </Modal>
			    <Modal size="md" show={subCatUpModal} backdrop="static" onHide={()=>{setSubCatUpModal(false)}}>
		        	<Modal.Header closeButton>Category</Modal.Header>		        	
			        <Modal.Body>
			        	<Form onSubmit={updateSubCat} >
						    <Form.Group className="mb-3">
			        			<Form.Label>Sub Category Name</Form.Label>
						    	<Form.Control type="text" defaultValue={catId!==undefined&&subcId!==undefined&&subEditable?mtCat[catId].sub_category[subcId].name:''} name="subc"  className="shadow-none br-2" required/>
						    </Form.Group>
						    <div  className="d-grid">
						    {
						    	catUpLoad?(
						    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    		<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
						    </div>
			        	</Form>
			        </Modal.Body>
			        <Modal.Footer></Modal.Footer>
			    </Modal>
			</Row>
		)
}