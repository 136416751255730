import React, {useState, useContext, useEffect} from 'react';
import { Alert, Spinner, Button, Modal, Form, Row, Col, Table, Pagination } from 'react-bootstrap';
import { DashMenu } from './dashMenu';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../../../App';


export const Consumables=()=>{
	const { setPage, postCall, getLoad, load, consume, setCounsume, conCat } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ editId, setEditId ] = useState();
	const [ editable, setEditable ] = useState(false);
	const updateItem=(e)=>{
		e.preventDefault();
		let name = e.target.p_name.value;
		let code = e.target.p_code.value;
		let cat = e.target.p_cat.value;
		let unit = e.target.unit.value;
		let manuf = e.target.p_manuf.value;
		let other_det = e.target.p_other_det.value;
		if (editable&&editId!==undefined) {
			if (name!==""&&cat!==""&&unit!=="") {
				setUpdateLoad(true);
				postCall('materials/edit/c/item/',{i_id:editId.substring(1), name:name,unique_code:code,c_id:cat, unit:unit,
					manufacturer_name:manuf,other_details:other_det}).then(res=>{
					if (res.data.status) {
						setCounsume(prev=>({...prev,[res.data.data.id]:res.data.data.data}));
						setUpdateModal(false);
					}else{
						$.dialog({
			              title: "Something went wrong!",
			              content: res.data.message,
			              type:'red'
			            });
					}				
					setUpdateLoad(false);				
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}else{
			if (validCode===undefined||validCode) {
				$.dialog({
	              title: "Invalid Code!",
	              content: 'Please enter a valid code!',
	              type:'red'
	            });
			}
			if (name!==""&&cat!==""&&!validCode&&validCode!==undefined&&unit!=="") {
				setUpdateLoad(true);
				postCall('materials/add/c/item/',{name:name,code:code,cat:cat,manuf:manuf,other_det:other_det, unit:unit}).then(res=>{
					if (res.data.status) {
						setCounsume(prev=>({...prev,[res.data.data.id]:res.data.data.data}));
						setUpdateModal(false);
					}else{
						$.dialog({
			              title: "Something went wrong!",
			              content: res.data.message,
			              type:'red'
			            });
					}				
					setUpdateLoad(false);				
				}).catch(err=>{
					setUpdateLoad(false);
				})
			}
		}

	}
	const [ validCode, setValidCode ] = useState();
	const [ checkCodeLoad, setCheckCodeLoad ] = useState(false);
	useEffect(()=>{
		if (!updateModal) {
			setValidCode();
			setEditId();
			setEditable(false);
		}
	},[updateModal]);
	const [ statChangeLoad, setStatChangeLoad ] = useState(false);
	const [ statId, setStatId ] = useState();
	const changeItemStat=(stat,id)=>{
		if (stat!==""&&id!=="") {
			setStatId(id);
			setStatChangeLoad(true);
			postCall('materials/status/c/item/',{i_id:id.substring(1),status:stat}).then(res=>{
				setStatChangeLoad(false);
			}).catch(err=>{
				setStatChangeLoad(false);
			})
		}
	}
	return(
			<Row className="dash-content" >
				<DashMenu />
				<Col xs={12} sm={12} md={12} className="dash-body">
					<Row className="dash-body-head bdr-1b px-1 bg1">
						<Col className="d-grid al-center py-2 tc-blue" >
							{
								load?(
									<span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
								):'Items'
							}
						</Col>
						<Col className="d-flex just-end al-center gap-1">
							<Button onClick={()=>{setUpdateModal(true);}} className="btn-t1 br3" size="sm" ><i className="bi bi-plus-lg"></i> New</Button>
						</Col>
					</Row>
					<Row className="dash-body-content">
						<Col>
							{
								consume!==undefined&&Object.keys(consume).length>0?(
									Object.keys(consume).map((item,i)=>(
										<Row key={`mach_${i}`} className="bdr-1b py-2" >
											<Col xs={12} sm={12} md={6} >
												<span className="d-grid" >{consume[item].name} / {consume[item].unique_code} </span>
												<span className="f14 tc2" >{conCat!==undefined?conCat[consume[item].category].name:''} </span>
											</Col>
											<Col xs={12} sm={12} md={6} className="flex-container just-end al-center" >
												<Button onClick={()=>{
													setEditable(true);
													setEditId(item);
													setUpdateModal(true);
												}} className="btn-t1 br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
											</Col>
										</Row>
									))
								):'Not found'
							}
						</Col>
					</Row>
				</Col>
				<Modal size="xl" fullscreen="lg-down" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        	<Modal.Header closeButton>Add new</Modal.Header>
		        	<Modal.Body>
			        	<div className="bdr-1 mb-2 bg-greay-1 p-2 br3" >
				        	<Form onSubmit={updateItem} id="purchase_add">
				        		<Row>
				        			<Col xs={12} sm={12} md={8}>
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Product name *</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text" defaultValue={editable&&editId!==undefined?consume[editId].name:''}  name="p_name" className="shadow-none br3" required/>
						        			</div>
						        		</Form.Group>
						        	</Col>
						        	<Col xs={12} sm={12} md={4} >				        		
						        		<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Unique Code*
						        			{
				        						checkCodeLoad?(
				        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
				        						):(
						        					validCode!==undefined?(
						        						validCode?(
						        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
						        						):(
						        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
						        						)
						        					):''
					        					)
				        					}
						        			</Form.Label>
						        			<div className="search-div pos-rel">
						        			{
						        				editable&&editId!==undefined?(
						        					<Form.Control type="text" defaultValue={consume[editId].unique_code}  name="p_code" className="shadow-none br3" disabled/>
						        				):(
							        				<Form.Control onBlur={(e)=>{
							        					let code = e.target.value;
							        					if (code!=="") {
								        					setCheckCodeLoad(true);
								        					postCall('materials/c/check/item/code/',{code:code}).then(res=>{
								        						setValidCode(res.data);
								        						setCheckCodeLoad(false);
								        					}).catch(err=>{
								        						setCheckCodeLoad(false);
								        					});
								        				}else{
								        					setValidCode();
								        				}
							        				}} type="text"  name="p_code" className="shadow-none br3" required/>
						        				)
						        			}						        				
						        			</div>
						        		</Form.Group>
					        		</Col>
				        		</Row>
				        		<Row>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Category*</Form.Label>
						        			<Form.Select name="p_cat" defaultValue={editable&&editId!==undefined?consume[editId].category:''} className="shadow-none br3" required>
					        					<option value="">Choose Category</option>
						        				{
						        					conCat!==undefined&&Object.keys(conCat).length>0?(
						        						Object.keys(conCat).map((item,i)=>(
						        							<option value={item}  key={`categ_${i}`}>{conCat[item].name}</option>
						        						))
						        					):''													
						        				}
											</Form.Select>
						        		</Form.Group>
				        			</Col>
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Unit of Messurement*</Form.Label>
						        			<Form.Select defaultValue={editable&&editId!==undefined?consume[editId].unit:''} name="unit" className="shadow-none br3" required>
					        					<option value="">Choose unit</option>
						        				<option value="nos">Number</option>
						        				<option value="bag">Bag</option>
						        				<option value="kg">Kg</option>
						        				<option value="lt">Litre</option>
											</Form.Select>
						        		</Form.Group>
				        			</Col>				        			
				        			<Col xs={12} sm={12} md={4} >
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Manufacturer</Form.Label>
						        			<div className="search-div pos-rel">
						        				<Form.Control type="text" defaultValue={editable&&editId!==undefined?consume[editId].manufacturer_name:''}  name="p_manuf" className="shadow-none br3" />
						        			</div>
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
				        				<Form.Group className="mb-3">
						        			<Form.Label className="tc2" >Other Details</Form.Label>
						        			<Form.Control defaultValue={editable&&editId!==undefined?consume[editId].other_details:''} type="text" as="textarea" name="p_other_det" rows="5" className="shadow-none br3"  />
						        		</Form.Group>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<Col>
					        			<Form.Group className="mb-3 d-grid al-center">			        		
							        		{
										    	updateLoad?(
										    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
										    	):(
										    	<Button type="submit" id="add_btn" className="btn-t1 br3" ><i className="bi bi-plus-lg"></i> Add Item</Button>
										    	)
										    }
									    </Form.Group>
					        		</Col>
				        		</Row>
				        		
			        		</Form>
		        		</div>
		        	</Modal.Body>
		        	<Modal.Footer></Modal.Footer>
        		</Modal>
			</Row>
		)
}