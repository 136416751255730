import React, {useContext} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';

export const Menu=()=>{
	const { page, setPage, getCall, postCall, setUsers, setLoad, setGetLoad, setUserPerm, setMt, setMtCat, setCounsume, setConCat, setProjects } = useContext(AppContext);
	return(
			<>
				<Col xs={12} sm={12} md={4} lg={3}  xl={2}   className="left-sidebar bg-greay-1">
					<Row style={{height:'51px'}} >
			            <Col xs={12} md={12} className="sidebar-head" >
			            	<span className="f20 f-400 px-4" >Contractor Inventory App</span>
			            </Col>
		            </Row>
		            <Row>
			            <Col xs={12} md={12} className="sidebar-body">            	
			            	<Row>
				            	<Col className={page==='home'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-speedometer2"></i> Dashboard</h5></Col>
			            	</Row>
			            	<Row>
				            	<Col onClick={()=>{
				            		setPage('mt');
				            		getCall(setLoad,'materials/get/m/items/',setMt);
				            		getCall(setLoad,'materials/get/m/categories/',setMtCat);
				            	}} className={page==='mt'||page==='m-category'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-tools"></i> Machine & Tools</h5></Col>
			            	</Row>
			            	<Row>
				            	<Col onClick={()=>{
				            		setPage('c-item');
				            		getCall(setLoad,'materials/get/c/items/',setCounsume);
				            		getCall(setLoad,'materials/get/c/categories/',setConCat);
				            	}} className={page==='c-item'||page==='c-cat'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-check2-square"></i> Consumables</h5></Col>
			            	</Row>
			            	<Row>
				            	<Col onClick={()=>{
				            		setPage('project');
				            		getCall(setLoad,'projects/get/projects/',setProjects);
				            		getCall(setGetLoad,'materials/get/c/items/',setCounsume);
				            	}} className={page==='project'||page==='project-item'||page==='pro-history'||page==='project-history-item'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-grid"></i> Projects</h5></Col>
			            	</Row>
			              	<Row>
				              	<Col onClick={()=>{
			              		setPage('user');
			              		getCall(setLoad,'user/management/get/users/',setUsers);
			              		getCall(setGetLoad,'user/management/get/permissions/',setUserPerm);	              			              		
			              	}} className={page==='user'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-people"></i> User Management</h5></Col>
				            </Row>				            
			              	<Row>
				              	<Col className={page==='settings'?'menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-gear"></i> Settings</h5></Col>
			              	</Row>
			              	<Row>
				              	<Col className="menu-item bdr-1b" onClick={()=>{
			                      localStorage.clear();
			                      window.location.reload();
			                      }} ><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-box-arrow-right"></i> Log out</h5></Col>
			                </Row>
			            </Col>
		            </Row>
		        </Col>
		        <Button onClick={()=>{
		        	$('.res-menu').slideToggle('slow','swing');
		        }} className="res-menu-btn btn-t2 br3" ><i className="bi bi-list f20"></i></Button>
		        <div className="res-menu">
		        	<div className="sidebar-head py-2 px-4">
		            	<span className="f20 f-400" >Contractor Inventory App</span>
		            </div>
		            <div className="res-menu-body">		        		
		            	<div className={page==='home'?'res-menu-item bdr-1b active':'res-menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-speedometer2"></i> Dashboard</h5></div>
		            <div onClick={()=>{
	              		setPage('mt');
	            		getCall(setLoad,'materials/get/m/items/',setMt);
	            		getCall(setLoad,'materials/get/m/categories/',setMtCat);
	              		$('.res-menu').slideToggle('slow','swing');	              			              		
	              	}} className={page==='mt'||page==='m-category'?'res-menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-tools"></i> Machine & Tools</h5></div>
	              	<div onClick={()=>{
	              		setPage('c-item');
			            		getCall(setLoad,'materials/get/c/items/',setCounsume);
			            		getCall(setLoad,'materials/get/c/categories/',setConCat);
	              		$('.res-menu').slideToggle('slow','swing');	              			              		
	              	}} className={page==='c-item'||page==='c-cat'?'res-menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-check2-square"></i> Consumables</h5></div>
	              	<div onClick={()=>{
	              		setPage('project');
	            		getCall(setLoad,'projects/get/projects/',setProjects);
	            		getCall(setGetLoad,'materials/get/c/items/',setCounsume);
	              		$('.res-menu').slideToggle('slow','swing');	              			              		
	              	}} className={page==='project'||page==='project-item'||page==='pro-history'||page==='project-history-item'?'res-menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-grid"></i> Projects</h5></div>
		            <div onClick={()=>{
		              		setPage('user');
		              		$('.res-menu').slideToggle('slow','swing');	              			              		
		              	}} className={page==='user'?'res-menu-item bdr-1b active':'menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-people"></i> User Management</h5></div>
		            
		            	<div className={page==='settings'?'res-menu-item bdr-1b active':'res-menu-item bdr-1b'}><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-gear"></i> Settings</h5></div>
		              	<div className="res-menu-item bdr-1b" onClick={()=>{
		                      localStorage.clear();
		                      window.location.reload();
		                      $('.res-menu').slideToggle('slow','swing');
		                }} ><h5 className="py-3 px-4 f-300 mb-0" ><i className="bi bi-box-arrow-right"></i> Log out</h5></div>
		            </div>
		        </div>
	        </>
		)
}